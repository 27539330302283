<template>
<div class="col p-2" style="text-align:center; background:white">
<a class="twitter-timeline" data-width="100%" data-height="270" :href="timelineLink">Tweets by iserworld</a>
<div class="m-2"> </div>
<a  :href="tweetButton" class="twitter-hashtag-button" data-size="large" data-related="iserworld" data-show-count="false">Tweet #Iser2023</a>
</div>
</template>


<script>
export default {
  props: {
    tweetButton: {
      type: String,
    },
    timelineLink: {
      type: String,
    },
  },
  data() {
    return {
    }
  },
  mounted() {
      let twitterScript = document.createElement('script');
      twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      document.head.appendChild(twitterScript);
  },

};
</script>
