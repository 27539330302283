<template>
  <ion-page>
    <div id="dashboard">
      <base-layout>
        <!--activated for platform only-->
        <header-bar></header-bar>

        <ion-content :scroll-x="false" :scroll-y="false">
          <!--activated for platform only-->
          <!--div class="header-text d-none d-md-block">
            <strong class="title" style="margin: 5px"></strong>
          </div-->
         

          <div class="dashboard-content" style="padding-bottom:70px"
               :style="{'background' : primaryColor}">
            <div class="loading-indicator" v-if="menulinks.length == 0">
              <ion-spinner style="color: white"></ion-spinner>
              <div style="color: white !important;">{{ wordings[currentLanguage].INITIALIZING_DATA }}</div>
            </div>
            
            <div v-if="1==2"> <!--will move, moment stays here-->
             <TwitterFeed timelineLink="https://twitter.com/iserworld?ref_src=twsrc%5Etfw" tweetButton="https://twitter.com/intent/tweet?button_hashtag=iser2023&ref_src=twsrc%5Etfw"> </TwitterFeed>
            </div>

            <div class="container-xl mt-3">
              <div class="row  g-1 justify-content-center">
                <template v-for="link in menulinks" :key="link.id">
                  <div v-if="link.type!='item item-divider'" class="col p-2 tile-wrapper" >
                    <dashboard-item :link="link" v-if="link.type!='item item-divider'"></dashboard-item>
                  </div>
                </template>
              </div>
            </div>
          </div>

        </ion-content>
        <!--menu-bar></menu-bar-->
      </base-layout>
    </div>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonSpinner,
  IonContent,
  toastController
} from "@ionic/vue";
import {mapState, mapActions, mapGetters} from "vuex";
import {isPlatform} from '@ionic/vue';
import DashboardItem from './DashboardItem.vue';
import TwitterFeed from "@/components/TwitterFeed";
const config = require('@/config')

export default {
  components: {
    IonPage,
    IonSpinner,
    IonContent,
    DashboardItem,
    TwitterFeed
  },
  data() {
    return {
      isDesktop: null,
      menulinks: [],
      title: config.full_name,
      confDate: config.conf_date,
      currentTime: '',
      polls: [],
      questions: [],
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapGetters('auth', ['user']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor']),
  },
  methods: {
    ...mapActions('menulinks', ['getAllLinks', 'getDashboardItems']),
    ...mapActions('sync', ['start']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions("personalDB", ['getItem', 'setTime', 'getUnreadNews']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions("settings", [
      "getSetting",
    ]),

    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',

          })
      return toast.present();
    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },
    async prepareLinks() {
      this.menulinks = (await this.getDashboardItems()).sort((a, b) => a.order - b.order);
    }
  },
  async created() {
    this.isDesktop = isPlatform('desktop');
    await this.prepareLinks();


  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.prepareLinks();
      }
    },
    '$route': {
      immediate: true,
      async handler() {
        let time = await this.getItem('timeStamps_' + this.currentConference);
        await this.setTime({name: 'timeStamps_' + this.currentConference, item: time});
        await this.getUnreadNews('posts');
      }
    }
  },
};
</script>
<style lang="scss">
.tile-wrapper{
  max-width: 240px;
}

@media(max-width: 372px) {
  .tile-wrapper{
    max-width: 150px
  }

}

@media(min-width: 372px) {
  .tile-wrapper{
    max-width: 190px
  }

}

</style>